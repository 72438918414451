import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/ubuntu/flomesh.io-main/src/layouts/Fixed/DocMdx.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "新的设计"
    }}>{`新的设计`}</h2>
    <ul>
      <li parentName="ul">{`从 HTTP 端点启动 Pipy 实例`}</li>
      <li parentName="ul">{`提供类似简单控制面板的内置代码库服务，并提供图形用户界面`}</li>
      <li parentName="ul">{`通过 `}<inlineCode parentName="li">{`pipy().import()`}</inlineCode>{` 和 `}<inlineCode parentName="li">{`pipy().export()`}</inlineCode>{` 实现，PJS 模块间的数据交互。作为推荐的用法，替代旧的实现`}</li>
    </ul>
    <h2 {...{
      "id": "新的过滤器"
    }}>{`新的过滤器`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`merge`}</inlineCode>{` 将多个消息交织成一个流`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`pack`}</inlineCode>{` 合并多条消息`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`split`}</inlineCode>{` 逐个字节地处理消息`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`handleXXX`}</inlineCode>{` `}<inlineCode parentName="li">{`onXXX`}</inlineCode>{` 过滤器的别名`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`proxySOCKS`}</inlineCode>{` 处理 SOCKS4 和 SOCKS5 流量`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`connectTLS`}</inlineCode>{` 和 `}<inlineCode parentName="li">{`acceptTLS`}</inlineCode>{` 从 `}<em parentName="li">{`listen`}</em>{` and `}<em parentName="li">{`connect`}</em>{` 分离出来，用于处理 TLS 流量`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`decompressMessage`}</inlineCode>{` and `}<inlineCode parentName="li">{`decompressHTTP`}</inlineCode>{` 使用zlib 来解压消息体`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`demuxHTTP`}</inlineCode>{` 和 `}<inlineCode parentName="li">{`muxHTTP`}</inlineCode>{` 合并了 HTTP 编解码器和 mux/demux 过滤器的操作`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`serveHTTP`}</inlineCode>{` 为了使用方便合并了 `}<em parentName="li">{`demuxHTTP`}</em>{` 和 `}<em parentName="li">{`replaceMessage`}</em>{` 过滤器的操作`}</li>
    </ul>
    <h2 {...{
      "id": "新的-api"
    }}>{`新的 API`}</h2>
    <ul>
      <li parentName="ul">{`函数 `}<inlineCode parentName="li">{`os.writeFile()`}</inlineCode>{` 用来写本地文件`}</li>
      <li parentName="ul">{`函数 `}<inlineCode parentName="li">{`algo.hash()`}</inlineCode>{` 用来计算 JS 值的哈希值`}</li>
      <li parentName="ul">{`类 `}<inlineCode parentName="li">{`Netmask`}</inlineCode>{` 用于处理 IP 地址段`}</li>
      <li parentName="ul">{`类 `}<inlineCode parentName="li">{`algo.Percentile`}</inlineCode>{` 用于百分位计算`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`crypto`}</inlineCode>{` 命名空间下的类 `}<inlineCode parentName="li">{`PublicKey`}</inlineCode>{`、`}<inlineCode parentName="li">{`PrivateKey`}</inlineCode>{`、`}<inlineCode parentName="li">{`Sign`}</inlineCode>{`、`}<inlineCode parentName="li">{`Verify`}</inlineCode>{`、`}<inlineCode parentName="li">{`Cipher`}</inlineCode>{`、`}<inlineCode parentName="li">{`Decipher`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "提升"
    }}>{`提升`}</h2>
    <ul>
      <li parentName="ul">{`支持 IPv6`}</li>
      <li parentName="ul">{`可以在 `}<inlineCode parentName="li">{`listen`}</inlineCode>{` 上限制并发连接数`}</li>
      <li parentName="ul">{`为涉及内部数据缓冲区的过滤器添加了大小限制选项`}</li>
      <li parentName="ul">{`跟踪数据缓冲区使用情况以及并发入站/出站的连接，并可通过 `}<inlineCode parentName="li">{`SIGTSTP`}</inlineCode>{` 转储`}</li>
      <li parentName="ul">{`HTTP 编解码器大调整，去掉了旧的 256KB 固定缓冲区`}</li>
      <li parentName="ul">{`为类 `}<inlineCode parentName="li">{`algo.Cache`}</inlineCode>{` 添加条目数量的限制，旧条目根据 LRU 策略被清理`}</li>
      <li parentName="ul">{`Dubbo 请求 ID 变成可以在 JS 中处理的 64 位范围的字符串`}</li>
      <li parentName="ul">{`PJS 中对象 key 排序符合标准处理`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      